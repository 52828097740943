import { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logOut } from '@stb-module-auth/actions';
import { CLEAR_PARAMETER } from '@stb-redux/extra-constants/system-parameter';
import { SET_PARAMETER } from '@stb-redux/extra-constants/system-parameter';
import { useCity, useProvinces } from './api';
import { useDateChange } from './data';
import swal from 'sweetalert';
import { createSwalButtons } from '../helper';

import { apiSgb, apiVolta, graphqlSgb, graphqlVolta } from '../axios-instance';

export const usePrevious = (val) => {
  const r = useRef();
  useEffect(() => {
    r.current = val;
  });
  return r.current;
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useFileSelector = () => {
  const f = useRef(document.createElement('input'));

  f.current.type = 'file';

  return f.current;
};

export const useSystemParameter = (isLoad = true) => {
  const [graphql, apiLoading, apiStatus, apiResponse] = useAxios();
  const listParameter = useSelector((state) => state.paramater.listParameters);
  const prevApiStatus = usePrevious(apiStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Array.isArray(listParameter) && listParameter.length === 0 && isLoad) {
      graphql({ id: 'getParameters' });
    }
  }, []);

  useEffect(() => {
    if (prevApiStatus !== undefined && apiStatus !== prevApiStatus) {
      switch (apiStatus) {
        case 1:
          dispatch({
            type: SET_PARAMETER,
            payload: apiResponse.data.parameters.nodes,
          });
          break;
      }
    }
  }, [prevApiStatus, apiStatus]);

  return [listParameter, apiLoading, graphql, apiStatus, apiResponse];
};

export const useLimitTableData = (
  isLoad = false,
  useSystemParameterLimit = true,
  limitProps = 8
) => {
  const [limitParameter, pLoading] = useSystemParameter(isLoad);

  let limit = useMemo(() => {
    if (!useSystemParameterLimit) {
      return limitProps;
    }
    const l = limitParameter.find((item) => item.name === 'LimitDisplayData');
    return Number(l ? l.pValue : 8);
  }, [limitParameter]);

  if (limit > 100) {
    limit = 100;
  }
  return [limit, pLoading];
};

export const useAxiosCreation = (api, _defaultHeader, url, method, timeout, initialLoading) => {
  const [states, setStates] = useState({
    loading: initialLoading,
    response: {},
    error: null,
    status: 1,
  });

  function withFormData(_headers, data, method = 'post', urlParam = null) {
    const _url = urlParam || url;
    const formHeader = {
      ..._headers,
      'Content-type': 'multipart/form-data',
    };
    const formData = new FormData();
    for (let key in data) {
      const d = data[key];
      formData.append(key, d);
    }

    let sent = api[method];
    if (sent) {
      return sent(_url, formData, {
        headers: formHeader,
      });
    }
    return null;
  }

  function callApi(data, headers = null, urlParam = null) {
    let a = null;
    const defaultHeader = _defaultHeader;
    const _headers = headers
      ? {
          ...defaultHeader,
          ...headers,
        }
      : defaultHeader;
    const _url = urlParam || url;
    switch (method) {
      case 'get':
        a = api.get(_url, {
          params: data,
          headers: _headers,
        });
        break;
      case 'post':
        a = api.post(_url, data, {
          headers: _headers,
        });
        break;
      case 'patch':
        a = api.patch(_url, data, {
          headers: _headers,
        });
        break;
      case 'postformdata':
        a = withFormData(_headers, data, 'post', _url);
        break;
      default:
      case 'put':
        a = api.put(_url, data, {
          headers: _headers,
        });
        break;
      case 'putformdata':
        a = withFormData(_headers, data, 'put', _url);
        break;
    }
    setStates({
      ...setStates,
      loading: true,
      status: -1,
    });
    return new Promise((resolve, reject) => {
      a.then((r) => {
        setTimeout(() => {
          if (r.data && !r.data.errors) {
            setStates({
              ...states,
              loading: false,
              response: r.data,
              status: 1,
            });
            resolve(r.data);
          } else {
            setStates({
              ...states,
              loading: false,
              error: r.data,
              status: [1000, 1001, 1003].includes(r.data?.http_status) ? 2 : 0,
            });
            reject(r.data);
          }
        }, timeout);
      }).catch((err) => {
        setTimeout(() => {
          setStates({
            ...states,
            loading: false,
            error: err?.response?.data,
            status: err?.response?.status === 401 ? 2 : 0,
          });
          reject(err);
        }, timeout);
      });
    });
  }

  return [callApi, states.loading, states.status, states.response, states.error];
};

export const useHeaderWithAuthorization = () => {
  const session = useSelector((state) => state.auth.session);
  function isAuthenticated() {
    const u = session.user;
    if (u && typeof u === 'object') {
      return true;
    }
    return false;
  }

  function getDefaultHeaders() {
    let headers = {
      'Content-type': 'application/json',
      apiKey: 'iBLTnMJhpwayXQkxWetXs1vqDjKXX00G',
    };
    if (isAuthenticated()) {
      const u = session.user;
      headers = {
        ...headers,
        Authorization: `Bearer ${u.access_token}`,
      };
    }
    return headers;
  }

  return [getDefaultHeaders, isAuthenticated];
};

export const useNodeAxios = (
  nodeApi,
  url = '/graphql',
  method = 'post',
  timeout = 1000,
  initialLoading = false
) => {
  const [getDefaultHeaders, isAuthenticated] = useHeaderWithAuthorization();
  const dispatch = useDispatch();

  const res = useAxiosCreation(nodeApi, getDefaultHeaders(), url, method, timeout, initialLoading);
  const prevApiStatus = usePrevious(res[2]);

  useEffect(() => {
    if (!isAuthenticated()) {
      dispatch(logOut());
      dispatch({
        type: CLEAR_PARAMETER,
      });
    }
  }, []);

  useEffect(() => {
    const apiStatus = res[2];
    if (prevApiStatus !== undefined && apiStatus !== prevApiStatus) {
      if (apiStatus === 2) {
        swal({
          title: 'Sesi login Anda telah habis',
          text: 'Harap login ulang.',
          icon: 'error',
          buttons: createSwalButtons('Oke'),
        }).then(() => {
          dispatch({
            type: CLEAR_PARAMETER,
          });
          dispatch(logOut());
        });
      }
    }
  }, [res[2], prevApiStatus]);

  return res;
};

export const useGoAxios = (
  goApi,
  url,
  method = 'post',
  withAuthorization = true,
  timeout = 1000,
  initialLoading = false
) => {
  const [getDefaultHeadersWithAuthorization] = useHeaderWithAuthorization();
  function getDefaultHeaders() {
    let headers = {
      ...(withAuthorization ? getDefaultHeadersWithAuthorization() : {}),
      'Content-type': 'application/json',
      apiKey: 'iBLTnMJhpwayXQkxWetXs1vqDjKXX00G',
    };

    return headers;
  }

  const res = useAxiosCreation(goApi, getDefaultHeaders(), url, method, timeout, initialLoading);
  const prevApiStatus = usePrevious(res[2]);
  const dispatch = useDispatch();

  useEffect(() => {
    const apiStatus = res[2];
    if (prevApiStatus !== undefined && apiStatus !== prevApiStatus) {
      if (apiStatus === 2 && url !== 'v1/auth/login-admin') {
        swal({
          title: 'Sesi login Anda telah habis',
          text: 'Harap login ulang.',
          icon: 'error',
          buttons: createSwalButtons('Oke'),
        }).then(() => {
          dispatch({
            type: CLEAR_PARAMETER,
          });
          dispatch(logOut());
        });
      }
    }
  }, [res[2], prevApiStatus]);

  return res;
};

export const useAxios = (_url, method = 'post', timeout = 1000) => {
  return useNodeAxios(graphqlSgb, '', method, timeout);
};

export const useAxios3 = (_url, method = 'post', timeout = 1000) => {
  return useNodeAxios(graphqlVolta, '', method, timeout);
};

export const useAxios2 = (url = '/', method = 'post', withAuthorization = true, timeout = 1000) => {
  return useGoAxios(apiVolta, url, method, withAuthorization, timeout);
};

export const useAxios4 = (
  url = '/',
  method = 'post',
  withAuthorization = true,
  timeout = 1000,
  initialLoading = false
) => {
  return useGoAxios(apiSgb, url, method, withAuthorization, timeout, initialLoading);
};

export const useWindowSize = () => {
  // source: https://dev.to/3sanket3/usewindowsize-react-hook-to-handle-responsiveness-in-javascript-3dcl
  const isWindowClient = typeof window === 'object';

  const [windowSize, setWindowSize] = useState(isWindowClient ? window.innerWidth : undefined);

  //👇
  useEffect(() => {
    //a handler which will be called on change of the screen resize
    function setSize() {
      setWindowSize(window.innerWidth);
    }

    if (isWindowClient) {
      //register the window resize listener
      window.addEventListener('resize', setSize);

      //un-register the listener
      return () => window.removeEventListener('resize', setSize);
    }
  }, [isWindowClient, setWindowSize]);
  //☝️

  return windowSize;
};

export const useDebounce = (callback, timeout = 500) => {
  const t = useRef();
  function startDebounce(e) {
    if (t.current) {
      clearTimeout(t.current);
    }
    t.current = setTimeout(() => {
      callback(e);
    }, timeout);
  }

  return startDebounce;
};

export const useDebounceAlt = (val, delay) => {
  const [debounceVal, setDebounceVal] = useState(val);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceVal(val);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [val]);

  return debounceVal;
};

export { useCity, useProvinces, useDateChange };
