import axios from 'axios';
import {
  requestOnAxiosFullfilled,
  responseOnAxiosFullfilled,
  responseOnReject,
} from './interceptor';

const apiSgb = axios.create({
  baseURL: process.env.REACT_APP_/dev/stb/web_GO_STB,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

apiSgb.interceptors.request.use(requestOnAxiosFullfilled);

apiSgb.interceptors.response.use(responseOnAxiosFullfilled, (err) => responseOnReject(err, apiSgb));

export default apiSgb;
