import swal from 'sweetalert';
import { createSwalButtons } from '../helper';
import store from '@stb-redux/store';
import { logOut } from '@stb/module/auth/actions';
import { CLEAR_PARAMETER } from '@stb/redux/extra-constants/system-parameter';
import {
  LOG_IN,
  REFRESHING_TOKEN,
  SET_SESSION,
  UPDATE_SESSION,
} from '@stb/redux/extra-constants/auth';
import apiSgb from './api-sgb';
import { jwtDecode } from 'jwt-decode';

/**
 * This function refreshes the access token if it almost expired.
 *
 * @return {Promise<object>} The updated axios config object.
 */
export async function requestOnAxiosFullfilled(config) {
  // Get the authentication state from the Redux store
  const authData = store.getState().auth;

  // Check if the action is one of the specified actions
  if ([LOG_IN, UPDATE_SESSION, REFRESHING_TOKEN, SET_SESSION].includes(authData.action)) {
    // Calculate the expiration date of the access token
    const expDate = new Date(authData.session.expdate * 1000);
    expDate.setMinutes(expDate.getMinutes() - 1);

    // Refresh the token if it has expired and the action is not REFRESHING_TOKEN
    if (authData.action !== REFRESHING_TOKEN && expDate < new Date()) {
      // Dispatch the REFRESHING_TOKEN action to indicate that the token is being refreshed
      store.dispatch({
        type: REFRESHING_TOKEN,
      });

      // Send a POST request to refresh the token
      const rs = await apiSgb.post('v1/auth/refresh-token', {
        access_token: authData.session.user.access_token,
        refresh_token: authData.session.user.refresh_token,
      });

      // Update the session with the new access token and refresh token
      store.dispatch({
        type: UPDATE_SESSION,
        payload: {
          access_token: rs.data.data.access_token,
          refresh_token: rs.data.data.refresh_token,
          exp_date: jwtDecode(rs.data.data.access_token).exp,
        },
      });

      // Add the new access token to the axios config headers
      config.headers['Authorization'] = `Bearer ${rs.data.data.access_token}`;
    } else {
      // Add the existing access token to the axios config headers
      config.headers['Authorization'] = `Bearer ${authData.session.user.access_token}`;
    }
  }

  // Return the updated axios config object
  return config;
}

export function responseOnAxiosFullfilled(res) {
  // Access Token was expired
  if (res.data.http_status === 1001) {
    store.dispatch({
      type: CLEAR_PARAMETER,
    });
    store.dispatch(logOut());
    swal({
      title: 'Sesi login Anda telah habis',
      text: 'Harap login ulang.',
      icon: 'error',
      buttons: createSwalButtons('Oke'),
    });
    return Promise.reject('Request failed with status code 401');
  }

  return res;
}

export async function responseOnReject(err, instance) {
  const originalConfig = err.config;
  // Check if the original URL is not 'v1/auth/login-admin' or 'v1/auth/refresh-token'
  // and the request has a err response
  if (
    !['v1/auth/login-admin', 'v1/auth/refresh-token'].includes(originalConfig.url) &&
    err.response
  ) {
    // Check if the response status is 401 (Unauthorized) and the request has not been retried
    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true; // Mark the request as retried

      try {
        // Get the authentication data from the Redux store
        const authData = store.getState().auth;

        // Send a POST request to refresh the access token
        const rs = await apiSgb.post('v1/auth/refresh-token', {
          access_token: authData.session.user.access_token,
          refresh_token: authData.session.user.refresh_token,
        });

        // Update the authentication data in the Redux store
        store.dispatch({
          type: UPDATE_SESSION,
          payload: {
            access_token: rs.data.data.access_token,
            refresh_token: rs.data.data.refresh_token,
          },
        });

        // Retry the original request with the updated config
        return instance(originalConfig);
      } catch (_err) {
        // Clear parameters in the Redux store
        store.dispatch({
          type: CLEAR_PARAMETER,
        });

        // Log out the user
        store.dispatch(logOut());

        // Display a swal notification
        swal({
          title: 'Sesi login Anda telah habis',
          text: 'Harap login ulang.',
          icon: 'error',
          buttons: createSwalButtons('Oke'),
        });

        // Reject with the original error
        return Promise.reject(_err);
      }
    }
  }

  // Reject with the original error
  return Promise.reject(err);
}
