import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import QRCode from 'qrcode.react';
import moment from 'moment';
import { DefaultImg } from '@stb/assets/images';
import { useHistory, useLocation } from 'react-router-dom';
import { ROLE_NAME_AND_ID_CONVENTION } from './constants';

const LEAD_WITH_HTTP_REGEX = /^https?:\/\//;

export const range = (num) => {
  const result = [];
  for (let i = 0; i < num; i++) {
    result.push(i);
  }
  return result;
};

export const getImageUrl = (image, defaultBase = process.env.REACT_APP_/dev/stb/web_GO_STB) => {
  let url = image;
  if (!url) {
    return DefaultImg;
  }
  if (!LEAD_WITH_HTTP_REGEX.test(image)) {
    url = `${defaultBase}/${image}`;
  }
  return url;
};

export const getMinioImageUrl = (path) => {
  return process.env.REACT_APP_MINIO_HOST + path;
};

export const downloadQRCode = (code, filename = 'image.png', imageType = 'image/png') => {
  const id = 'hidden-qrcode';
  let hiddenBattery = document.getElementById(id);
  if (!hiddenBattery) {
    hiddenBattery = document.createElement('div');
    hiddenBattery.style.display = 'none';
    hiddenBattery.id = id;
    document.body.appendChild(hiddenBattery);
  }
  const idQrcode = 'id-download-' + code;
  ReactDOM.render(
    <QRCode id={idQrcode} size={500} renderAs="canvas" value={code || ''} />,
    hiddenBattery
  );
  setTimeout(() => {
    const canvas = document.getElementById(idQrcode);
    const urlCanvas = canvas.toDataURL(imageType);
    const link = document.createElement('a');
    link.download = filename;
    link.href = urlCanvas;
    link.click();
    //canvas.remove();
  }, 200);
};

export const isSTBActive = (lastActivityStr) => {
  let status = false;
  const utc = moment().utc();
  const lastActivity = moment.utc(lastActivityStr);
  if (lastActivity.isValid()) {
    //const lastActivityAfter5Minutes = lastActivity.add(5, 'minutes');
    const d = utc.diff(lastActivity, 'minutes');
    status = Math.abs(d) <= 5;
  }
  return status;
};

export const setNegativeLatitude = (latitude) => {
  return latitude;
};

export const setPositiveLongitude = (longitude) => {
  return longitude;
};

export const userAndMotorcycleInfoFormat = (data) => ({
  userId: data?.user?.id ?? null,
  userName: data?.user?.name ?? '',
  userMobileNumber: data?.user?.mobileNumber ?? '',
  customerId: data?.customerMotorcycle.customer.id ?? null,
  customerName: data?.customerMotorcycle.customer.name ?? '',
  customerType: data?.customerMotorcycle.customer.customerType?.name ?? '',
  chassisNumber: data?.customerMotorcycle.dealerMotorcycle.chassisNumber ?? '',
  machineNumber: data?.customerMotorcycle.dealerMotorcycle.machineNumber ?? '',
  motorTypeName: data?.customerMotorcycle.dealerMotorcycle.motorType.name ?? '',
  motorcycleNumber: data?.customerMotorcycle.motorcycleNumber ?? '',
});

export const batteryInfoFormat = (data) => {
  const batteryAlarmStatus = {
    '00': 'Single core voltage low alarm',
    '01': 'Single core voltage high alarm',
    '02': 'Battery core low temperature alarm',
    '03': 'Battery high temperature alarm',
    '04': 'High total voltage alarm',
    '05': 'Low total voltage alarm',
    '06': 'Single core pressure difference alarm is too large',
    '07': 'MOS high temperature warning',
    '08': 'Ambient low temperature ',
    '09': 'Ambient high temperature warning',
    '000': 'None',
  };

  return {
    id: data?.id ?? null,
    code: data?.code ?? '',
    percentCapacity: data?.percentCapacity ?? '',
    latitude: data?.latitude ? setNegativeLatitude(data.latitude, data.code) : '',
    longitude: data?.longitude ? setPositiveLongitude(data.longitude) : '',
    speed: data?.speed ?? '',
    modifiedDate: data?.modifiedDate ?? '',
    totalPerjalanan: data?.totalPerjalanan ?? '',
    totalVoltage: data?.totalVoltage ?? '',
    totalCurrent: data?.totalCurrent ?? '',
    totalDischarge: data?.totalDischarge ?? '',
    suhu: data?.suhu ?? '',
    gsm: data?.gsm ?? '',
    kekuatanSinyal: data?.kekuatanSinyal ?? '',
    alarm: data && data.alarm ? batteryAlarmStatus[data.alarm] : '',
    totalCharge: data?.totalCharge ?? '',
    kapasitasAh: data?.kapasitasAh ?? '',
    onOffCharge: data?.onOffCharge ?? '',
    totalCell: data?.totalCell ?? '',
    cellJSON: data?.cellJSON ?? '',
    stateOfHealth: data?.stateOfHealth ?? '',
    note: data?.note ?? '',
    isActive: data?.isActive ?? '',
    modifiedBy: data?.modifiedBy?.name ?? '',
    batteryStatus: {
      id: data?.batteryStatus.id,
      name: data?.batteryStatus.name,
    },
    voltaBatteries: {
      totalCount: data?.voltaBatteries.totalCount ?? -1,
      nodes: data?.voltaBatteries.nodes ?? [],
    },
    stbBatteriesByBatteryCode: {
      totalCount: data?.stbBatteriesByBatteryCode.totalCount ?? -1,
      nodes: data?.stbBatteriesByBatteryCode.nodes ?? [],
    },
  };
};

export const formulaChargingStatus = (percentage) => {
  if (percentage >= 80) {
    return 'fully charged';
  } else if (percentage > 50 && percentage < 80) {
    return 'Charging';
  } else {
    return '';
  }
};

export const sgbInfoFormat = (data) => ({
  qrCode: data?.qrcode ?? '',
  name: data?.name ?? '',
  address: data?.address ?? '',
  latitude: data?.latitude ?? '',
  longitude: data?.longitude ?? '',
  stbStatus: data?.online ? 'online' : 'offline' ?? '',
});

export const sgbBatteryInfoFormat = (data) => ({
  amountBattery50: data?.battery_gte_50_percent_amount ?? '',
  amountBattery80: data?.battery_available_amount ?? '',
  bookingBatteryAmountAvailable: data?.battery_available_to_booking_amount ?? '',
});

export const sgbDoorInfoFormat = (data) => {
  if (data) {
    return {
      doorID: data?.doorId ?? '',
      batteryCode: data?.batteryCode ?? '',
      doorStatus: data?.isOpen ? 'Terbuka' : 'Tertutup' ?? '',
      batteryCapacity: data?.id02109001 ?? '',
      chargingStatus: formulaChargingStatus(data?.id02109001 ?? ''),
    };
  } else {
    return {
      doorID: '',
      batteryCode: '',
      doorStatus: '',
      batteryCapacity: '',
      chargingStatus: '',
    };
  }
};

export const copyObjectWithoutValue = (object) => {
  const keys = Object.keys(object);
  const newObj = {};
  keys.forEach((key) => {
    newObj[key] = null;
  });
  return newObj;
};

export const useForm = (initialState = {}, isBlockingInitial = false) => {
  const [isBlocking, setIsBlocking] = useState(isBlockingInitial);
  const [formData, setFormData] = useState(initialState);
  const [errorList, setErrorList] = useState(copyObjectWithoutValue(initialState));

  const onDataChange = (key, value, ignoreBlocking = false) => {
    if (!ignoreBlocking) {
      setIsBlocking(true);
    }
    setFormData({ ...formData, [key]: value });
    setErrorList({
      ...errorList,
      [key]: null,
    });
  };
  const onBatchDataChange = (changedData, ignoreBlocking = false) => {
    if (!ignoreBlocking) {
      setIsBlocking(true);
    }
    setFormData({ ...formData, ...changedData });

    const target = {};
    Object.keys(changedData).forEach((key) => (target[key] = null));
    setErrorList({
      ...errorList,
      ...target,
    });
  };

  const resetForm = () => {
    setIsBlocking(isBlockingInitial);
    setFormData(initialState);
    setErrorList(copyObjectWithoutValue(initialState));
  };
  return {
    formData,
    errorList,
    onDataChange,
    onBatchDataChange,
    setErrorList,
    isBlocking,
    resetForm,
  };
};

export function numberFormat(number, prefix, returnNumber = false) {
  let new_number = number.replace(/[^\d]/g, '').toString();
  if (returnNumber) {
    return number ? parseInt(number.replace(/[^,\d]/g, '')) : 0;
  }
  let split = new_number.split(','),
    last = split[0].length % 3,
    number_string = split[0].substr(0, last),
    thousand = split[0].substr(last).match(/\d{3}/gi);

  if (thousand) {
    const separator = last ? '.' : '';
    number_string += separator + thousand.join('.');
  }

  number_string = split[1] !== undefined ? number_string + ',' + split[1] : number_string;
  return prefix === undefined ? number_string : number_string ? prefix + number_string : '';
}

export function createSwalButtons(confirmText, cancelText) {
  return {
    ...(cancelText && {
      cancel: {
        text: cancelText,
        value: false,
        visible: true,
        className: 'btn-secondary rounded-pill',
        closeModal: true,
      },
    }),
    confirm: {
      text: confirmText,
      value: true,
      visible: true,
      className: 'btn-primary rounded-pill',
      closeModal: true,
    },
  };
}

export function createSwalButtonsWithLoading(confirmText, cancelText) {
  return {
    ...(cancelText && {
      cancel: {
        text: cancelText,
        value: false,
        visible: true,
        className: 'btn-secondary rounded-pill',
        closeModal: true,
      },
    }),
    confirm: {
      text: confirmText,
      value: true,
      visible: true,
      className: 'btn-primary rounded-pill',
      closeModal: false,
    },
  };
}

export function toLocalTime(date) {
  return moment.utc(date).local();
}

export function toRupiah(string) {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  }).format(string);
}

export const getUniqueArrayObjectById = (arrayObject = [{}]) => {
  const uniqueIds = [];

  const unique = arrayObject.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.id);
    if (!isDuplicate) {
      uniqueIds.push(element.id);
      return true;
    }
    return false;
  });

  return unique;
};

export const boolStringToBool = (boolString = 'False') => {
  return typeof boolString === 'string' ? JSON.parse(boolString.toLowerCase()) : boolString;
};

export function arrayToHashMap(array, key) {
  const hashMap = {};
  array.forEach((item) => {
    hashMap[item[key]] = item;
  });
  return hashMap;
}

// prettier-ignore
export function getSotStatus(sot = {
  type: undefined,
  isUsed: undefined,
  deletedDate: undefined,
}, bet = {
  id: undefined,
  isActive: undefined,
  isSuccessIn: undefined,
  isSuccessOut: undefined,
}) {
  if (sot.type === 'BOOKING') {
    if (!sot.isUsed && !sot.deletedDate && bet.id && bet.isActive) {
      return 'Booking';
    } else if (sot.isUsed && !sot.deletedDate && bet.id && bet.isActive) {
      return 'Booking Sukses';
    } else if (!sot.isUsed && !sot.deletedDate && !bet.id) {
      return 'Booking Kedaluwarsa';
    } else if (!sot.isUsed && sot.deletedDate && bet.id && !bet.isActive) {
      return 'Booking Dibatalkan';
    } else if (!sot.isUsed && sot.deletedDate && !bet.id) {
      return 'Booking Dibatalkan';
    }
  } else if (sot.type === 'CHARGING') {
    if (sot.isUsed && !sot.deletedDate && bet.id && bet.isActive) {
      return 'Sukses SCB';
    } else if (!sot.isUsed && !sot.deletedDate && bet.id && bet.isActive && !bet.isSuccessIn) {
      return 'SCB Kedaluwarsa';
    } else if (!sot.isUsed && sot.deletedDate && bet.id && !bet.isActive) {
      return 'SCB Dibatalkan';
    } else if (!sot.isUsed && !sot.deletedDate && bet.id && bet.isActive && bet.isSuccessIn) {
      return 'SCB';
    }
  } else if (sot.type === 'ENTRUSTED') {
    if (sot.isUsed && !sot.deletedDate && bet.id && bet.isActive && bet.isSuccessOut) {
      return 'Tukar Titip Sukses';
    } else if (!sot.isUsed && !sot.deletedDate && !bet.id) {
      return 'Tukar Titip Kedaluwarsa';
    } else if (!sot.isUsed && sot.deletedDate && !bet.id) {
      return 'Tukar Titip Dibatalkan';
    }
  }
  return '-';
}

export const saveFileToDisk = async (response, filenamArg) => {
  try {
    // Extract the filename from the Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];
    let filename = filenamArg;

    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (filenameMatch !== null && filenameMatch[1]) {
        filename = filenameMatch[1].replace(/['"]/g, ''); // Remove quotes
      }
    }

    // Create a new Blob object using the response data
    const blob = new Blob([response.data]);

    // Create a link element
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Remove the link element from the DOM
    document.body.removeChild(link);
  } catch (error) {
    console.error('Download error:', error);
  }
};

export const useSearchParams = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => {
    if (!search) return {};
    const params = new URLSearchParams(search);
    const result = {};

    for (const [key, value] of params) {
      const numberValue = Number(value);
      result[key] = isNaN(numberValue) ? value : numberValue;
    }

    return result;
  });

  const setSearchParams = (newParams) => {
    history.push({
      search: new URLSearchParams({ ...searchParams, ...newParams }).toString(),
    });
  };

  const clearSearchParams = () => {
    history.push({
      search: '',
    });
  };

  return {
    searchParams,
    setSearchParams,
    clearSearchParams,
  };
};

export const filterColumsByPermission = (columns, currentRoleId) => {
  const roleName = ROLE_NAME_AND_ID_CONVENTION[currentRoleId];

  return columns.filter((column) => {
    if (column.showInRoles) {
      return column.showInRoles.includes(roleName);
    }

    if (column.hiddenInRoles) {
      return !column.hiddenInRoles.includes(roleName);
    }
    return true;
  });
};
